.faqHeading {
  text-align: center;
}

.instruction {
  padding: 0;
  padding-left: 24px;
  padding-right: 24px;
  // border: 1px solid #f0f0f1;
  // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  list-style-type: none;
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin-top: 24px;

  li {
    text-align: left;
    padding: 10px;

    // .question {
    //   font-family: "Noto Sans SC";
    //   font-weight: 300;
    // }

    .answer {
      margin-left: 20px;
      // font-family: "Noto Sans SC";
      // font-weight: 300;
    }
  }
}
