.carousel-root {
  .carousel {
    // height: 600px !important;
    border-radius: 16px !important;
    .carousel-slider {
      width: 100%;
      .slider-wrapper {
        .slider {
          .slide {
            img {
              height: 600px !important;
              // width: 100%;
              // border-radius: 8px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
